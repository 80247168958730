<template>
  <div>
    <div>
            Yrrmbot V_{{appVersion}}<br/>
            <div title="https://id.twitch.tv/oauth2/authorize?client_id=h2iguosg28mphq1x76rrjv4yiu1k3c&redirect_uri=https://yrrmbot.onrender.com/api/updateToken&response_type=code&scope=chat:read+chat:edit+moderator:manage:chat_messages+moderator:manage:banned_users">(Login?)</div>
        </div>
        <div class="container">
            <div class="role">
                Viewers
                <hr/>
                <div class="command" style="display: none;">
                    !watchtime
                    <div class="explanation">
                        <div class="explanation-text">
                            Muestra el tiempo que el usuario ha estado viendo a Suzu.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !watchtime <b>[usuario]</b>
                        </div>
                        <div class="explanation-text">
                            - usuario: Nombre de usuario, si no está presente se toma el que invoca la acción.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Ver el tiempo que yairmon33 ha estado viendo a Suzu:</b><br/>
                            <div class="usage">!watchtime @yairmon33</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            @yairmon33 ha estado viendo a Suzu durante 20 días 20 horas (500 horas)
                        </div>
                    </div>
                </div>
                <div class="command">
                    !frase
                    <div class="explanation">
                        <div class="explanation-text">
                            Se usa para decir frases específicas y aleatorias.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !frase <b>[#][frase_id]</b>
                        </div>
                        <div class="explanation-text">
                            - frase_id: Identificador de la frase, es la posición guardada de la frase (puede llevar el símbolo numeral).<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Pedirle al bot que diga la frase 2:</b><br/>
                            <div class="usage">!frase #2</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Frase #2: No soy vago, estoy en modo ahorro de energía -yairmon33 (11/2021)
                        </div>
                    </div>
                </div>
            </div>
            <div class="role">
                VIPs
                <hr/>
                <div class="command">
                    !frase
                    <div class="explanation">
                        <div class="explanation-text">
                            Se usa para el manejo de frases: guarda y dice frases aleatorias.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !frase <b>[usuario] &lt;texto&gt;</b><br/>
                            !frase <b>[#][frase_id]</b>
                        </div>
                        <div class="explanation-text">
                            - usuario: Nombre de usuario, si no está presente se toma el que invoca la acción.<br/>
                            - texto: Texto de la frase a guardar.<br/>
                            - frase_id: Identificador de la frase, es la posición guardada de la frase (puede llevar el símbolo numeral).<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Guardar, frase y decir una aleatoria:</b><br/>
                            <div class="usage">!frase @yairmon33 ¿Yo, raro? Qué va, soy una "Edición limitada".</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Vale, frase #4 guardada
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Pedirle al bot que diga la frase 2:</b><br/>
                            <div class="usage">!frase #2</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Frase #2: No soy vago, estoy en modo ahorro de energía -yairmon33 (11/2021)
                        </div>
                    </div>
                </div>
                <div class="command">
                    !mostrar
                    <div class="explanation">
                        <div class="explanation-text">
                            Muestra el valor actual de una secuencia del contador especificado, esto lo hace sin aumentar su valor.<br/>
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !mostrar <b>&lt;cmd&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - cmd: Es el comando, es decir, el comando que posee el contador.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Mostrar el valor actual del comando para ver el contador:</b><br/>
                            <div class="usage">!mostrar !saludo</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Hola @yairmon33, bienvenido al chat :) (8)
                        </div>
                    </div>
                </div>
                <div class="command">
                    !timevip
                    <div class="explanation">
                        <div class="explanation-text">
                            Muestra el tiempo que le queda al usuario ingresado de VIP.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !timevip <b>&lt;usuario&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - usuario: Nombre de usuario, es un parámetro obligatorio.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Mostrar la fecha de VIP para un usuario:</b><br/>
                            <div class="usage">!timevip @usuario1</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Al VIP de @usuario1 le quedan 20 días
                        </div>
                    </div>
                </div>
            </div>
            <div class="role">
                Mods
                <hr/>
                <div class="command">
                    !dado
                    <div class="explanation">
                        <div class="explanation-text">
                            Obtiene aleatoriamente un número entre 1-6.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !dado
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <div class="usage">!dado</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Sacaste 3
                        </div>
                    </div>
                </div>
                <div class="command">
                    !recuerdame
                    <div class="explanation">
                        <div class="explanation-text">
                            Programa un temporizador para decir el texto en un tiempo dado por el usuario.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !recuerdame <b>&lt;num&gt;[&lt;letter (s,m,h,d)&gt;] &lt;Msg&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - num: Unidad de tiempo para iniciar el contador (siempre obligatorio).<br/>
                            - letter: Letra multiplicador de la unidad (s=segundos, m=minutos, h=horas, d=días),
                              esta letra no es obligatoria y cuando no se ponga ninguna letra se tomará la unidad
                              como segundos.<br/>
                            - texto: Texto de la frase a decir al terminar el tiempo.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Recordar dentro de 5 minutos:</b><br/>
                            <div class="usage">!recuerdame 5m ¿Te tomaste la pasta?</div>
                        </div>
                        <div class="title">Salida (5 minutos después):</div>
                        <div class="explanation-text">
                            @yairmon33, ¿Te tomaste la pasta?
                        </div>
                    </div>
                </div>
                <div class="command">
                    !delfrase
                    <div class="explanation">
                        <div class="explanation-text">
                            Elimina una frase guardada anteriormente.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !delfrase <b>&lt;frase_id&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - frase_id: Identificador de la frase, es la posición guardada de la frase.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Eliminar frase que guardamos previamente:</b><br/>
                            <div class="usage">!delfrase 3</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Frase "El tiempo sin ti es empo" eliminada
                        </div>
                    </div>
                </div>
                <div class="command">
                    !ping
                    <div class="explanation">
                        <div class="explanation-text">
                            Visualizar tiempo que ha estado activo el bot desde último reinicio.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !ping
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <div class="usage">!ping</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            !pong (Activo 4 horas)
                        </div>
                    </div>
                </div>
                <div class="command">
                    !quien
                    <div class="explanation">
                        <div class="explanation-text">
                            Busca en sus registros si el usuario indicado se ha cambiado el nombre anteriormente basado en su ID de usuario único.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !quien <b>&lt;usuario&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - usuario: Nombre de usuario, es un parámetro obligatorio.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Ver los nombres que ha tenido antes el bot:</b><br/>
                            <div class="usage">!quien @yrrmbot</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            yrrmbot me recuerda a... yairmon33
                        </div>
                    </div>
                </div>
                <div class="command">
                    !id
                    <div class="explanation">
                        <div class="explanation-text">
                            Gestiona los nombres de los usuarios del chat en diferentes juegos.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !id <b>&lt;usuario&gt; [gameId] [gameName]</b>
                        </div>
                        <div class="explanation-text">
                            - usuario: Nombre de usuario, es un parámetro obligatorio.<br/>
                            - gameId: Nombre del usuario en el juego, es un parámetro opcional, cuando no viene, el bot dice cuales son sus IDs guardados.<br/>
                            - gameName: Nombre del juego, es un parámetro opcional, por defecto se asigna Apexlegends (este nombre nunca debe tener espacios).<br/>
                        </div>
                        <div class="title">Ejemplo1:</div>
                        <div class="explanation-text">
                            <b>Añadir al bot a la lista de ids:</b><br/>
                            <div class="usage">!id @yrrmbot yrrmbotTTv</div>
                        </div>
                        <div class="title">Ejemplo2:</div>
                        <div class="explanation-text">
                            <b>Ver los ids del bot:</b><br/>
                            <div class="usage">!id @yrrmbot</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            @yairmon33, @yrrmbot se llama yrrmbotttv en Apexlegends, yrrmbotttv en Minecraft
                        </div>
                    </div>
                </div>
                <div class="command">
                    !addsaludo
                    <div class="explanation">
                        <div class="explanation-text">
                            Agrega un saludo personalizado para un usuario, este se ejecuta sólo una vez cada 5 horas.
                            En caso de que el usuario tenga varios saludos agregados, el bot tomará uno aleatorio.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !addsaludo <b>&lt;usuario&gt; [msg]</b>
                        </div>
                        <div class="explanation-text">
                            - usuario: Nombre de usuario, es un parámetro obligatorio.<br/>
                            - msg: Frase que se quiere usar para saludar, es un parámetro opcional y en caso de
                            que no se proporcione, el bot tomará un saludo genérico.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Agregar un saludo para un usuario:</b><br/>
                            <div class="usage">!addsaludo @usuario1 Hola de nuevo, viewer favorito de todos :)</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Saludo para usuario1 agregado TehePelo
                        </div>
                    </div>
                </div>
                <div class="command">
                    !di
                    <div class="explanation">
                        <div class="explanation-text">
                            Repite la frase que ingresa el usuario.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !di <b>&lt;msg&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - msg: Frase que se quiere usar para repetir, es un parámetro obligatorio.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <div class="usage">!di Verificando emotes suzuuHeart</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Verificando emotes suzuuHeart
                        </div>
                    </div>
                </div>
                <div class="command">
                    !dc
                    <div class="explanation">
                        <div class="explanation-text">
                            Envía un mensaje de prueba al Discord.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !dc <b>&lt;msg&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - msg: Frase que se quiere usar para enviar al canal configurado, es un parámetro obligatorio.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <div class="usage">!dc Verificando discord :smile:</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Vale @yairmon33
                        </div>
                    </div>
                </div>
                <div class="command">
                    !guachtaim
                    <div class="explanation">
                        <div class="explanation-text">
                            Horas que han pasado desde el primer Stream de Suzu.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !guachtaim
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <div class="usage">!guachtaim</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            @yrrmbot ha estado viendo a Suzu durante 4605.3 hora(s).
                        </div>
                    </div>
                </div>
                <div class="command">
                    !addcmd
                    <div class="explanation">
                        <div class="explanation-text">
                            Agrega un comando personalizado para el chat, este se ejecuta cada vez que el usuario con el nivel adecuado
                            lo invoca en el chat.<br/>
                            Los niveles se pueden definir con nombres o con IDs.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !addcmd <b>&lt;cmd&gt; [-lvl] &lt;msg&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - cmd: Es el comando, es decir, el texto que invocará el comando, es un parámetro obligatorio.<br/>
                            - lvl: Nivel del usuario, es un parámetro opcional, por defecto es 6-viewer, puede ser un id o un valor de los siguientes:
                            1-broadcaster,2-moderator,3-vip,4-regular,5-subscriber,6-viewer<br/>
                            - msg: Frase que se quiere usar para el comando, es un parámetro obligatorio. Acepta las siguientes variables:
                            <strong>($user)</strong> usuario que invoca el comando; <strong>($touser)</strong> usuario que se menciona junto con el comando (si no se menciona a nadie se llama el usuario que invoca el comando);
                            <strong>($count)</strong> contador que incrementa cuando se llama el comando.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Agregar un comando solo para suscriptores, vips, regulares y mods:</b><br/>
                            <div class="usage">!addcmd !saludo -lvl=subscriber Hola <strong>($touser)</strong>, bienvenido al chat :)</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            @yrrmbot, comando añadido -> !saludo
                        </div>
                    </div>
                </div>
                <div class="command">
                    !editcmd
                    <div class="explanation">
                        <div class="explanation-text">
                            Modifica un comando personalizado para el chat.<br/>
                            Los niveles se pueden definir con nombres o con IDs
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !editcmd <b>&lt;cmd&gt; [-lvl] &lt;msg&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - cmd: Es el comando, es decir, el texto que invocará el comando, es un parámetro obligatorio.<br/>
                            - lvl: Nivel del usuario, es un parámetro opcional (si no se agrega este parámetro se debe agregar la frase), puede ser un id o un valor de los siguientes:
                            1-broadcaster,2-moderator,3-vip,4-regular,5-subscriber,6-viewer<br/>
                            - msg: Frase que se quiere usar para el comando, es un parámetro opcional (si no se agrega este parámetro se debe agregar el nivel). Acepta las siguientes variables:
                            <strong>($user)</strong> usuario que invoca el comando; <strong>($touser)</strong> usuario que se menciona junto con el comando (si no se menciona a nadie se llama el usuario que invoca el comando);
                            <strong>($count)</strong> contador que incrementa cuando se llama el comando.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Actualizar el comando para que puedan usarlo todos:</b><br/>
                            <div class="usage">!editcmd !saludo -lvl=viewer</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            @yrrmbot, comando actualizado -> !saludo
                        </div>
                    </div>
                </div>
                <div class="command">
                    !delcmd
                    <div class="explanation">
                        <div class="explanation-text">
                            Elimina un comando personalizado para el chat.<br/>
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !delcmd <b>&lt;cmd&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - cmd: Es el comando, es decir, el texto que invoca el comando, es un parámetro obligatorio.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Remover el comando que ya existe creado:</b><br/>
                            <div class="usage">!delcmd !saludo</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            @yrrmbot, comando eliminado -> !saludo
                        </div>
                    </div>
                </div>
                <div class="command">
                    !reset
                    <div class="explanation">
                        <div class="explanation-text">
                            Reinicia una secuencia el contador especificado, a cero o a un valor específico.<br/>
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !reset <b>&lt;cmd&gt;</b> [value]
                        </div>
                        <div class="explanation-text">
                            - cmd: Es el comando, es decir, el comando que posee el contador a reiniciar.<br/>
                            - value: Es el valor, el número que poseerá el contador, es un parámetro opcional, por defecto se reinicia a 0<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Actualizar el comando para que el contador sea 10:</b><br/>
                            <div class="usage">!reset !saludo 10</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            @yrrmbot, comando actualizado -> Hola @yairmon33, bienvenido al chat :) (10)
                        </div>
                    </div>
                </div>
                <div class="command">
                    !setvip
                    <div class="explanation">
                        <div class="explanation-text">
                            Agrega una marca al usuario para conocer cuando termina su VIP temporal.
                            Una vez ha expirado el VIP, el sistema escribirá un mensaje en el chat avisando que el VIP
                            ha caducado.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !setvip <b>&lt;usuario&gt; &lt;fecha[YYYY-MM-DD]&gt;/+&lt;num&gt;[&lt;letter (h,d,m,y)&gt;] &lt;Msg&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - usuario: Nombre de usuario, es un parámetro obligatorio.<br/>
                            - fecha: Fecha en que caduca el VIP del usuario ingresado, debe ser siempre en el formato YYYY-MM-DD, Ej: 2023-05-24.<br/>
                            - letter: Letra multiplicador de la unidad (h=horas, d=días, m=meses, y=años)<br/>
                        </div>
                        <div class="title">Ejemplo1:</div>
                        <div class="explanation-text">
                            <b>Agregar una fecha de VIP para un usuario:</b><br/>
                            <div class="usage">!setvip @usuario1 2023-05-24</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Vale @yrrmbot, al VIP de @usuario1 le quedan 20 días
                        </div>
                        <div class="title">Ejemplo2:</div>
                        <div class="explanation-text">
                            <b>Sumar días de VIP para un usuario:</b><br/>
                            <div class="usage">!setvip @usuario1 +10d</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Vale @yrrmbot, al VIP de @usuario1 le quedan 30 días
                        </div>
                    </div>
                </div>
                <div class="command">
                    !removevip
                    <div class="explanation">
                        <div class="explanation-text">
                            Remueve el tiempo al usuario ingresado de VIP.
                        </div>
                        <div class="title">Uso:</div>
                        <div class="usage">
                            !removevip <b>&lt;usuario&gt;</b>
                        </div>
                        <div class="explanation-text">
                            - usuario: Nombre de usuario, es un parámetro obligatorio.<br/>
                        </div>
                        <div class="title">Ejemplo:</div>
                        <div class="explanation-text">
                            <b>Remover la fecha de VIP para un usuario:</b><br/>
                            <div class="usage">!removevip @usuario1</div>
                        </div>
                        <div class="title">Salida:</div>
                        <div class="explanation-text">
                            Vale @yrrmbot, @usuario1 ha quedado sin fecha de expiración
                        </div>
                    </div>
                </div>




            </div>
        </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {version} from '../../../package'

export default {
  name: 'DocsView',
  components: {

  },
  data: () => ({
      appVersion:version
  })
}
</script>

<style>
body {
  padding: 50px;
  font: 24px "Lucida Grande", Helvetica, Arial, sans-serif;
}

a {
  color: #00B7FF;
}

.container {
  padding: 20px
}

.role {
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 30px;
}

.command {
  font-size: 30px;
  font-weight: bold;
  padding-left: 10px;
  padding-bottom: 30px;
}

.title {
  padding-top: 10px;
}

.explanation {
  font-size: medium;
  font-weight: bold;
  padding-left: 10px;
}

.explanation-text {
  font-size: medium;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 5px;
}

.usage {
  font-size: medium;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: italic;
  color: rgb(112, 112, 112);
}
</style>