<template>
  <div>
    Yrrmbot V_{{appVersion}}
  </div>
</template>

<script>
// @ is an alias to /src
import {version} from '../../../package'

export default {
  name: 'HomeView',
  components: {
    
  },
  data: () => ({
      appVersion:version
  })
}
</script>

<style>
body {
  padding: 50px;
  font: 24px "Lucida Grande", Helvetica, Arial, sans-serif;
}

a {
  color: #00B7FF;
}

.container {
  padding: 20px
}

.role {
  font-size: 50px;
  font-weight: bold;
  padding-bottom: 30px;
}

.command {
  font-size: 30px;
  font-weight: bold;
  padding-left: 10px;
  padding-bottom: 30px;
}

.title {
  padding-top: 10px;
}

.explanation {
  font-size: medium;
  font-weight: bold;
  padding-left: 10px;
}

.explanation-text {
  font-size: medium;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 5px;
}

.usage {
  font-size: medium;
  font-weight: normal;
  padding-top: 5px;
  padding-bottom: 5px;
  font-style: italic;
  color: rgb(112, 112, 112);
}
</style>